import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@components';
import { laptop } from '@styles';

export interface SliderProps {
  className?: string;
  restart?: boolean;
  toggleSlide?: (index: number) => void;
  children: React.ReactNode;
}

export interface SlideProps {
  className?: string;
  children: React.ReactNode;
}

const StyledSlider = styled.div`
  position: relative;
`;

const Slides = styled.div`
  overflow-x: hidden;
`;

const Inner = styled.div<{ offset: number; }>`
  display: flex;
  transform: translateX(-${({ offset }) => offset}px);
  will-change: transform;
  transition: all 0.5s ease;
`;

const StyledSlide = styled.div<{ slideWidth: number; }>`
  flex: 0 0 ${({ slideWidth }) => slideWidth}px;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  offset: none;
  background: none;
  cursor: pointer;
  padding: 0;
`;

const ButtonPrev = styled(Button)`
  left: -1rem;

  ${laptop(css`
    left: -50px;
  `)}
`;

const ButtonNext = styled(Button)`
  right: -1rem;

  ${laptop(css`
    right: -50px;
  `)}
`;

const SliderContext = createContext({ slideWidth: 0 });

export const Slider: React.FC<SliderProps> = (props) => {
  const {
    restart = false,
    className,
    toggleSlide,
    children } = props;

  const [ index, setIndex ] = useState(0);
  const [ width, setWidth ] = useState(0);
  const sliderRef = useRef<HTMLDivElement>(null);

  const prevSlide = () => {
    const prevIndex = (index > 0) ? index - 1 : index;
    setIndex(prevIndex);
  };

  const nextSlide = () => {
    const nextIndex = (index < React.Children.count(children) - 1) ? index + 1 : index;
    setIndex(nextIndex);
  };

  const calcWidth = useCallback(() => {
    if (sliderRef.current) {
      setWidth(sliderRef.current.offsetWidth);
    }
  }, [sliderRef]);

  useEffect(() => {
    calcWidth()
    window.addEventListener('resize', calcWidth);
    return () => window.removeEventListener('resize', calcWidth);
  }, [calcWidth]);

  useEffect(() => {
    if (toggleSlide) {
      toggleSlide(index);
    }
  }, [index, toggleSlide]);

  useEffect(() => {
    if (restart) {
      setIndex(0);
    }
  }, [restart]);

  return (
    <SliderContext.Provider value={{ slideWidth: width }}>
      <StyledSlider ref={sliderRef} className={className}>
        <Slides>
          <Inner offset={width * index}>
            {children}
          </Inner>
        </Slides>
        {
          index > 0
          && <ButtonPrev onClick={prevSlide}>
            <Icon glyph={'arrow-left'} iconSize={33} />
          </ButtonPrev>
        }
        {
          index < React.Children.count(children) - 1
          && <ButtonNext onClick={nextSlide}>
              <Icon glyph={'arrow-right'} iconSize={33} />
          </ButtonNext>
        }
      </StyledSlider>
    </SliderContext.Provider>
  );
};

export const Slide: React.FC<SlideProps> = ({ className, children }) => {
  const { slideWidth } = useContext(SliderContext)

  return (
    <StyledSlide className={className} slideWidth={slideWidth}>
      {children}
    </StyledSlide>
  )
};
