import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import emailjs from '@emailjs/browser';
import { Icon, Text } from '@components';
import logo from '@images/logo-dark.svg';
import { Data } from '../../interfaces/data';
import * as S from './styles';

export interface CallMeProps {
  data: Data;
}

const duration = 400;
const transitionName = 'fade-in-out';

const Animate = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  
  &.${transitionName}-enter {
    opacity: 0;
  }

  &.${transitionName}-enter-active {
    opacity: 1;
    transition: opacity ${duration}ms ease-out;
  }

  &.${transitionName}-exit {
    opacity: 1;
  }

  &.${transitionName}-exit-active {
    opacity: 0;
    transition: opacity ${duration}ms ease-out;
  }
`;

export const CallMe: React.FC<CallMeProps> = ({ data: { callme } }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [success, setSuccess] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [agreement, setAgreement] = useState(false);
  const [errorText, setErrorText] = useState<string | null>();

  const handlerSubmitForm = async (e: any) => {
    e.preventDefault();

    if (name && phone && email && subject && message && agreement) {
      try {
        await emailjs.sendForm(
          'service_iw4w85c',
          'template_5v8mhtr',
          e.target,
          '43e2mv5OR8-PGZ0yw'
        );

        setSuccess(true);
      } catch (err) {
        setErrorText('RequestError');
      }
    } else {
      setErrorText('Заполнены не все обязательные поля.');
    }
  };

  return (
    <>
      <S.Button onClick={() => setShowPopup(true)}>
        <Icon glyph={'direct-inbox'} iconSize={31} />
      </S.Button>
      <CSSTransition nodeRef={ref} in={showPopup} timeout={duration} classNames={transitionName} unmountOnExit>
        <Animate ref={ref}>
          <S.Popup>
            {
              !success
              && <>
                <form onSubmit={handlerSubmitForm}>
                  <Text textSize={'sm'} weight={700}>{callme.title}</Text>
                  <S.Input
                      name={'name'}
                      value={name}
                      type={'text'}
                      placeholder={callme.name}
                      onChange={(e) => setName(e.target.value)}
                      required
                  />
                  <S.Input
                      name={'phone'}
                      value={phone}
                      type={'text'}
                      placeholder={callme.phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                  />
                  <S.Input
                      name={'email'}
                      value={email}
                      type={'email'}
                      placeholder={callme.email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                  />
                  <S.Input
                      name={'subject'}
                      value={subject}
                      type={'text'}
                      placeholder={callme.subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                  />
                  <S.Textarea
                      as={'textarea'}
                      name={'message'}
                      value={message}
                      placeholder={callme.message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                  />
                  <S.Agreement active={agreement} onClick={() => setAgreement(!agreement)}>
                    {callme.agreement}
                  </S.Agreement>
                  <S.SendButton type={'submit'}>{callme.sendButton}</S.SendButton>
                </form>
                <S.Error>{errorText}</S.Error>
              </>
            }
            {
              success
              && <S.Success>
                <S.Logo src={logo} />
                <div dangerouslySetInnerHTML={{ __html: callme.successText }} />
              </S.Success>
            }
          </S.Popup>
          <S.Bg ref={ref} onClick={() => setShowPopup(false)} />
        </Animate>
      </CSSTransition>
    </>
  );
};
