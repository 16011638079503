import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import App from './App';
import { GlobalStyles, FontsStyles, theme } from '@styles';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const ThemeSelector: React.FC = () => (
  <ThemeProvider theme={theme}>
    <App />
    <FontsStyles />
    <GlobalStyles />
  </ThemeProvider>
);

root.render(
  <ThemeSelector />
);