import styled, { css } from 'styled-components';
import { assetsMapping } from './assets';

export type IconType = keyof typeof assetsMapping;

export interface IconProps {
  glyph: IconType;
  iconSize?: number;
  iconColor?: string;
}

const Icon = styled.div<IconProps>`
  width: ${({ iconSize = 24 }) => `${iconSize}px`};
  height: ${({ iconSize = 24 }) => `${iconSize}px`};
  ${({ glyph, iconColor }) => {
    if (iconColor) {
      return css`
        background-color: ${iconColor};
        mask: url(${assetsMapping[glyph]});
        mask-repeat: no-repeat;
        mask-size: contain;
        transition: 0.3s ease-out;
      `;
    }
    
    return css`
      background-image: url(${assetsMapping[glyph]});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    `;
  }};
`;

export { Icon };
