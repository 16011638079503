import React from 'react';
import * as C from '@styles';
import { IconType, Slide } from '@components';
import { useMatchMedia } from '@utils';
import { breakpoints } from '@config';
import { Data } from '../../interfaces/data';
import * as S from './styles';

export interface CasesProps {
  data: Data;
}

export const Cases: React.FC<CasesProps> = ({ data: { cases } }) => {
  const laptopMatch = useMatchMedia(breakpoints.laptop);

  return (
    <S.StyledCases id={'cases'}>
      <C.Container>
        <C.SectionTitle>{cases.title}</C.SectionTitle>
        <S.StyledSlider>
          {
            cases.items.map(({title, descr, task, done, result}, index) => (
              <Slide key={index}>
                <S.Case>
                  <S.Head>
                    <S.Number glyph={`d${index + 1}` as IconType} iconSize={laptopMatch ? 150 : 50} iconColor={'#000000'} />
                    <S.Title as={'h2'}>{title}</S.Title>
                  </S.Head>
                  <S.Descr>
                    <S.SubTitle>{cases.locale.descrTitle}</S.SubTitle>
                    {descr}
                  </S.Descr>
                  <S.Task>
                    <S.SubTitle>{cases.locale.taskTitle}</S.SubTitle>
                    {task}
                  </S.Task>
                  <S.Done as={'ul'}>
                    {
                      done.map(({ title, descr}, index) => (
                        <S.DoneItem key={index}>
                          <S.DoneNumber>{index + 1}</S.DoneNumber>
                          <S.SubTitle>{title}</S.SubTitle>
                          {descr}
                        </S.DoneItem>
                      ))
                    }
                  </S.Done>
                  <S.Result>
                    <S.ResultInner>
                      <S.SubTitle textColor={'textLight'}>{cases.locale.resultTitle}</S.SubTitle>
                      <S.ResultList>
                        {
                          result.map((item, index) => (
                            <S.ResultItem key={index}>{item}</S.ResultItem>
                          ))
                        }
                      </S.ResultList>
                    </S.ResultInner>
                  </S.Result>
                </S.Case>
              </Slide>
            ))
          }
        </S.StyledSlider>
      </C.Container>
    </S.StyledCases>
  );
}
