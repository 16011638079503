import React from 'react';
import styled, { css } from 'styled-components';
import { Text } from '@components';
import * as C from '@styles';
import { laptop } from '@styles';
import { Data } from '../../interfaces/data';

export interface TariffsProps {
  data: Data;
}

const StyledTariffs = styled.section`
  padding-top: 60px;

  ${laptop(css`
    padding-top: 90px;
  `)}
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 10px;

  ${laptop(css`
    flex-direction: row;
    align-items: stretch;
  `)}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 100%;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 29px;
  padding: 40px;

  ${laptop(css`
    min-height: 400px;
  `)}
`;

const Title = styled(Text).attrs(() => ({
  weight: 700,
}))`
  font-size: ${({ theme }) => theme.fontSize.lg}px;

  ${laptop(css`
    font-size: 40px;
  `)}
`;

const Price = styled(Text).attrs(() => ({
  weight: 700,
  textColor: 'first',
}))`
  display: flex;
  align-items: center;
  flex: 1 0 100%;
  font-size: 89px;
`;

const Comments = styled(Text).attrs(() => ({
  lineHeight: 1.2,
}))`
  font-size: 18px;
  margin-top: 40px;

  ${laptop(css`
    margin-top: 76px;
  `)}
`;

export const Tariffs: React.FC<TariffsProps> = ({ data: { tariffs } }) => (
  <StyledTariffs id={'tariffs'}>
    <C.Container>
      <C.SectionTitle as={'h2'} dangerouslySetInnerHTML={{ __html: tariffs.title } } />
      <Cards>
        {
          tariffs.items.map(({ title, price }, index) => (
            <Card key={index}>
              <Title>{title}</Title>
              <Price>{price}</Price>
            </Card>
          ))
        }
      </Cards>
      <Comments dangerouslySetInnerHTML={{ __html: tariffs.comments}} />
    </C.Container>
  </StyledTariffs>
);
