import styled, { css } from 'styled-components';
import { Text } from '@components';
import { laptop } from './helpers';

export interface ContainerProps {
  fullHeight?: boolean;
  center?: boolean;
}

export const Container = styled.div<ContainerProps>`
  max-width: 1200px;
  height: ${({ fullHeight }) => fullHeight && '100vh'};
  box-sizing: border-box;
  padding: 0 1rem;
  margin: 0 auto;
  
  ${({ center }) => center && css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

export const SectionTitle = styled(Text).attrs(() => ({
  lineHeight: 1.2,
  weight: 700,
}))`
  font-size: 35px;
  text-align: center;
  margin: 0 0 20px;

  ${laptop(css`
    font-size: ${({ theme }) => theme.fontSize.title}px;
    text-align: left;
  `)}
`;