import styled, { DefaultTheme } from 'styled-components';
import { rgba } from 'polished';

export interface TextProps {
  textSize?: keyof DefaultTheme['fontSize'];
  lineHeight?: number;
  textColor?: keyof DefaultTheme['colors'];
  textOpacity?: number;
  weight?: 400 | 700;
}

const Text = styled.div<TextProps>`
  font-size: ${({ textSize = 'md', theme }) => theme.fontSize[textSize]}px;
  color: ${({ textColor = 'text', textOpacity = 1, theme }) => rgba(theme.colors[textColor], textOpacity)};
  line-height: ${({ lineHeight = 1 }) => lineHeight};
  font-weight: ${({ weight = 400 }) => weight};
`;

export { Text };
