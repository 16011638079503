import { Theme } from '../interfaces/styled';

export const theme: Theme = {
  family: 'Candara, sans-serif',
  colors: {
    text: '#000000',
    textLight: '#FFFFFF',
    first: '#FFA41B',
    second: '#000839',
    bg: '#FFFFFF'
  },
  fontSize: {
    title: 60,
    xl: 24,
    lg: 20,
    md: 16,
    sm: 14,
  },
};
