import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, Text } from '@components';
import * as C from '@styles';
import { laptop } from '@styles';
import { Data } from '../../interfaces/data';

export interface AboutProps {
  data: Data;
}

const StyledAbout = styled.section`
  padding-top: 60px;

  ${laptop(css`
    padding-top: 95px;
  `)}
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  ${laptop(css`
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
  `)}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  text-align: center;
  border: 3px solid ${({ theme }) => theme.colors.first};
  border-radius: 28px;
  padding: 46px 20px 20px;

  ${laptop(css`
    flex: 0 1 calc(33.3% - 60px);
  `)}
`;

const Value = styled(Text).attrs(() => ({
  weight: 700,
}))`
  font-size: 48px;
`;

export const About: React.FC<AboutProps> = ({ data: { about } }) => (
  <StyledAbout id={'about'}>
    <C.Container>
      <C.SectionTitle as={'h2'} dangerouslySetInnerHTML={{ __html: about.title } } />
      <Cards>
        {
          about.items.map(({ icon, value, title }, index) => (
            <Card key={index}>
              <Icon glyph={icon} iconSize={60} />
              <Value>{value}</Value>
              <Text textSize={'lg'} lineHeight={1.7}>{title}</Text>
            </Card>
          ))
        }
      </Cards>
    </C.Container>
  </StyledAbout>
);
