import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, Text } from '@components';
import * as C from '@styles';
import { laptop } from '@styles';
import { Data } from '../../interfaces/data';

export interface ContactsProps {
  data: Data;
}

const StyledContacts = styled.section`
  padding: 60px 0;

  ${laptop(css`
    padding: 95px 0 102px;
  `)}
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;

  ${laptop(css`
    flex-direction: row;
    align-items: stretch;
  `)}
`;

const Card = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
  flex: 1 1 100%;
  min-height: 240px;
  text-align: center;
  text-decoration: none;
  background: ${({ theme }) => theme.colors.first};
  box-shadow: 0 4px 4px rgba(160, 160, 160, 0.2);
  border-radius: 29px;
`;

export const Contacts: React.FC<ContactsProps> = ({ data: { contacts }}) => (
  <StyledContacts id={'contacts'}>
    <C.Container>
      <C.SectionTitle as={'h2'} dangerouslySetInnerHTML={{ __html: contacts.title } } />
      <Cards>
        {
          contacts.items.map(({ icon, title, href }, index) => (
            <Card key={index} href={href}>
              <Icon glyph={icon} iconSize={45} />
              <Text textSize={'lg'} textColor={'textLight'}>{title}</Text>
            </Card>
          ))
        }
      </Cards>
    </C.Container>
  </StyledContacts>
);
