import { Data } from '../interfaces/data';

export const en: Data = {

  // Header section
  header: {
    items: [
      {
        title: 'About us',
        to: 'about',
      },
      {
        title: 'Corporate clients',
        to: 'services',
        options: {
          tab: 'corporate',
        },
      },
      {
        title: 'Private clients',
        to: 'services',
        options: {
          tab: 'individual',
        },
      },
      {
        title: 'Our advantages',
        to: 'pluses',
      },
      {
        title: 'Our prices',
        to: 'tariffs',
        offset: -70,
      },
      {
        title: 'Our cases',
        to: 'cases',
      },
      {
        title: 'contacts',
        to: 'contacts',
      },
    ],
  },

  // Promo section
  promo: {
    title: 'Cyprus',
    subtitle: `Consulting services<br />
               for corporate and private clients`,
  },

  // About section
  about: {
    title: 'About us',
    items: [
      {
        icon: 'job',
        value: '2018',
        title: 'Incorporation date',
      },
      {
        icon: 'rating',
        value: '15',
        title: 'Experts',
      },
      {
        icon: 'handshake',
        value: '80+',
        title: 'Loyal customers',
      },
      {
        icon: 'community',
        value: '5+',
        title: 'Partners',
      },
      {
        icon: 'project',
        value: '100+',
        title: 'Successfully implemented projects',
      },
    ],
  },

  // Direction section
  direction: {
    title: 'We work with 10 professional areas',
    items: [
      {
        descr: 'Registration and administration, as well as management of legal entities in Cyprus and not only',
      },
      {
        descr: 'Creation of a physical presence / opening of turnkey offices in Cyprus',
      },
      {
        descr: 'Migration services',
      },
      {
        descr: 'Real estate transactions',
      },
      {
        descr: 'Risk management and project management',
      },
      {
        descr: 'Accounting and audit',
      },
      {
        descr: 'Property management',
      },
      {
        descr: 'HR management',
      },
      {
        descr: 'Banking administration',
      },
      {
        descr: 'Client Care Service',
      },
    ],
  },

  // Services
  services: {
    title: 'Our services',
    locale: {
      corporateTab: 'Corporate clients',
      individualTab: 'Private clients',
    },
    corporate: [
      [
        {
          title: 'Registration/ Administration/ Management of legal entities',
          items: [
            {
              icon: 'search-normal',
              descr: 'KYC and comprehensive verification',
            },
            {
              icon: 'path',
              descr: 'Company registration',
            },
            {
              icon: 'layer',
              descr: 'Getting documents from the registry',
            },
            {
              icon: 'cloud',
              descr: 'Storing a company file',
            },
            {
              icon: 'search-normal',
              descr: 'Compilation and verification corporate documents',
            },
            {
              icon: 'category',
              descr: 'Redomicilation of legal entities in Cyprus',
            },
            {
              icon: 'copy',
              descr: 'Maintaining corporate registers and document management',
            },
            {
              icon: 'shield-tick',
              descr: 'Legalization and certification of documents (notary, apostille)',
            },
          ],
        },
        {
          title: 'Creation of a physical presence / opening of turnkey offices in Cyprus',
          items: [
            {
              icon: 'setting',
              descr: 'Office rent',
            },
            {
              icon: 'profile-2-user',
              descr: 'Recruitment of employees',
            },
            {
              icon: 'user-octagon',
              descr: 'Legal entity management services',
            },
            {
              icon: 'tag-user',
              descr: 'Advising on physical presence',
            },
            {
              icon: 'copy',
              descr: 'Organization of office infrastructure (including IT infrastructure)',
            },
          ],
        },
      ],
      [
        {
          title: 'Budgeting / Planning / Banking',
          items: [
            {
              icon: 'path',
              descr: 'Execution of bank transactions and orders',
            },
            {
              icon: 'copy',
              descr: 'Preparation of the budget report',
            },
            {
              icon: 'box-search',
              descr: 'Preparation of documents for loan processing',
            },
            {
              icon: 'receipt-discount',
              descr: 'Passing the KYC compliance procedure and banking and brokerage requests',
            },
            {
              icon: 'dollar-circle',
              descr: 'Project or company budget planning, as well as its execution and control',
            },
            {
              icon: 'message-text',
              descr: 'Execution and execution of bank payment orders (AML transaction verification procedures)',
            },
          ],
        },
        {
          title: 'Accounting and auditing',
          items: [
            {
              icon: 'tag-user',
              descr: 'Preparation of financial statements',
            },
            {
              icon: 'layer',
              descr: 'Preparation of tax calculations for the reporting period',
            },
            {
              icon: 'copy',
              descr: 'Submission of reports to the Registry',
            },
            {
              icon: 'setting',
              descr: 'Maintaining accounting registers and accounting books',
            },
            {
              icon: 'services-sms',
              descr: 'Preparation and filing of tax returns, as well as payment of taxes',
            },
            {
              icon: 'user-octagon',
              descr: 'Implementation of monthly salary payments to employees with the preparation of relevant documents and payment of contributions to the DSS',
            },
          ],
        },
      ],
      [
        {
          title: 'Migration services',
          items: [
            {
              icon: 'message-text',
              descr: 'Application for a work permit for employees',
            },
            {
              icon: 'directbox-notif',
              descr: 'Application for a residence permit for family members of the employees',
            },
            {
              icon: 'data',
              descr: 'Client Care Service – employee relocation services',
            },
            {
              icon: 'path',
              descr: 'Registration of companies in the Migration Department',
            },
            {
              icon: 'copy',
              descr: 'Assistance in the preparation of documents and support to the migration service',
            },
          ],
        },
        {
          title: 'Tax consulting',
          items: [
            {
              icon: 'moneys',
              descr: 'Calculation of corporate tax',
            },
            {
              icon: 'layer',
              descr: 'Advising on the tax regime in Cyprus',
            },
            {
              icon: 'tag-user',
              descr: 'Submission of tax returns to the tax authorities',
            },
            {
              icon: 'services-sms',
              descr: 'Submission of reports to the social insurance authorities, if required',
            },
            {
              icon: 'discount-shape',
              descr: 'Registration of companies with the Tax authorities and the Social Insurance Department',
            },
          ],
        },
      ],
      [
        {
          title: 'Recruitment process',
          items: [
            {
              icon: 'services-people',
              descr: 'Search for candidates',
            },
            {
              icon: 'copy',
              descr: 'Preparation of employment contracts',
            },
            {
              icon: 'receipt-discount',
              descr: 'Calculation of taxes and fees',
            },
            {
              icon: 'dollar-circle',
              descr: 'Payroll and payslips',
            },
            {
              icon: 'profile-tick',
              descr: 'Conducting interviews with potential candidates',
            },
            {
              icon: 'message-text',
              descr: 'Submission of relevant reports to the public authorities',
            },
            {
              icon: 'discount-shape',
              descr: 'Conducting mutual settlements with tax authorities and social insurance authorities',
            },
          ],
        },
        {
          title: 'Additional services',
          items: [
            {
              icon: 'brush',
              descr: 'Translation of documents',
            },
            {
              icon: 'key-square',
              descr: 'Apostille/ legalization of documents',
            },
            {
              icon: 'copy',
              descr: 'Copies of documents',
            },
            {
              icon: 'services-sms',
              descr: `Preparation of the Client\\'s electronic archive`,
            },
            {
              icon: 'layer',
              descr: 'Certification of documents and translation of documents into a foreign language',
            },
          ],
        }
      ],
    ],
    individual: [
      [
        {
          title: 'Migration services',
          items: [
            {
              icon: 'message-text',
              descr: 'Advising on existing options for permits (visas)',
            },
            {
              icon: 'house',
              descr: 'Client Care Service – employee relocation services',
            },
            {
              icon: 'path',
              descr: 'Registration of companies in the Migration Department',
            },
            {
              icon: 'copy',
              descr: 'Obtaining a residence permit for family members of third countries/citizens of Cyprus',
            },
            {
              icon: 'directbox-notif',
              descr: 'Customer Care Service – Family relocation services',
            },
          ],
        },
        {
          title: 'Tax consulting',
          items: [
            {
              icon: 'copy',
              descr: 'Preparation of financial statements',
            },
            {
              icon: 'receipt-discount',
              descr: 'Preparation of tax calculations for the reporting period',
            },
            {
              icon: 'setting',
              descr: 'Submission of reports to the Registry',
            },
            {
              icon: 'edit',
              descr: 'Maintaining accounting registers and accounting books',
            },
            {
              icon: 'services-sms',
              descr: 'Preparation and filing of tax returns, as well as payment of taxes',
            },
            {
              icon: 'user-octagon',
              descr: 'Implementation of monthly salary payments to employees with the preparation of relevant documents and payment of contributions to the DSS',
            },
          ],
        },
      ],
      [
        {
          title: 'Client Care Service',
          items: [
            {
              icon: 'user',
              descr: 'Concierge services',
            },
            {
              icon: 'user',
              descr: 'Translation services',
            },
            {
              icon: 'bank',
              descr: 'Support to government agencies',
            },
            {
              icon: 'clock',
              descr: 'Adaptation after relocation',
            },
            {
              icon: 'building-3',
              descr: 'Selection of kindergartens/schools/Universities',
            },
            {
              icon: 'truck',
              descr: 'Transportation services',
            },
          ],
        },
        {
          title: 'Project management',
          items: [
            {
              icon: 'edit',
              descr: 'Planning',
            },
            {
              icon: 'layer',
              descr: 'Structuring projects',
            },
            {
              icon: 'setting',
              descr: 'Step-by-step project implementation plan',
            },
            {
              icon: 'setting',
              descr: 'Наблюдение и контроль за реализацией проекта',
            },
            {
              icon: 'services-flash',
              descr: 'Risk management',
            },
            {
              icon: 'services-sms',
              descr: 'Preparation of required documents',
            },
            {
              icon: 'shield-tick',
              descr: 'Legalization of documents',
            },
            {
              icon: 'frame',
              descr: 'Monitoring and control over the implementation of the project',
            },
            {
              icon: 'keyboard',
              descr: 'If necessary, obtaining licenses and appropriate permits',
            },
          ],
        },
      ],
      [
        {
          title: 'Real estate transactions',
          items: [
            {
              icon: 'repeate-music',
              descr: 'Purchase/Sale/Rental',
            },
            {
              icon: 'document',
              descr: 'Preparation of lease agreements',
            },
            {
              icon: 'house',
              descr: 'Real estate maintenance',
            },
            {
              icon: 'house',
              descr: 'Selection of real estate/land on request',
            },
            {
              icon: 'receipt-2',
              descr: 'Legal verification of the real estate developer',
            },
            {
              icon: 'receipt-2',
              descr: 'Legal verification of real estate on the primary market',
            },
          ],
        },
        {
          title: 'Additional services',
          items: [
            {
              icon: 'brush',
              descr: 'Translation of documents',
            },
            {
              icon: 'key-square',
              descr: 'Apostille/ legalization of documents',
            },
            {
              icon: 'copy',
              descr: 'Copies of documents',
            },
            {
              icon: 'services-sms',
              descr: `Preparation of the Client\\'s electronic archive`,
            },
            {
              icon: 'layer',
              descr: 'Certification of documents and translation of documents into a foreign language',
            },
          ],
        },
      ],
    ]
  },

  // Pluses section
  pluses: {
    title: 'Our advantages',
    items: [
      {
        icon: 'people',
        title: 'A team of qualified professionals',
      },
      {
        icon: 'user',
        title: 'Client orientation',
      },
      {
        icon: 'programming-arrow',
        title: 'Prompt feedback',
      },
      {
        icon: 'flash',
        title: 'Effective Client service',
      },
      {
        icon: 'profile',
        title: 'Individual approach',
      },
      {
        icon: 'programming-arrows',
        title: 'An integrated approach to solving problems',
      },
      {
        icon: 'like',
        title: 'Reasonable and transparent prices',
      },
      {
        icon: 'lamp',
        title: 'Creativity in solutions',
      },
      {
        icon: 'receipt',
        title: 'Flexible terms of cooperation and payment',
      },
    ],
  },

  // Cases
  cases: {
    title: 'Our cases',
    locale: {
      descrTitle: 'Brief information',
      taskTitle: 'Task',
      resultTitle: 'Result',
    },
    items: [
      {
        title: 'Moving a family of four to Cyprus',
        descr: `A family of four (2 adults and 2 children) was planning to move to Cyprus. The client considers the island in the long term.`,
        task: `To determine the migration status for the family with the subsequent submission of documents for obtaining a residence permit. Moving the family to Cyprus as soon as possible.`,
        done: [
          {
            title: 'The Customer Care Service is promptly connected',
          },
          {
            title: 'The optimal variant of the residence permit and the optimal migration status have been selected.',
          },
          {
            title: 'A consultation was held on tax residency and taxation in Cyprus.',
          },
          {
            title: 'Preparation of documents for opening a bank account in Cyprus',
          },
          {
            title: 'Preparation of an appropriate package of documents, including assistance with translation and legalization of documents',
          },
          {
            title: 'Selection of a school for children and the beginning of classes before obtaining a residence permit',
          },
          {
            title: 'Selection of an apartment in accordance with the Client\'s request, registration of a lease agreement and related documents',
          },
          {
            title: 'Support during the medical examination in Cyprus',
          },
          {
            title: 'Accompanying a Client to the Migration Department',
          },
        ],
        result: [
          'The move to Cyprus took place a month after the conclusion of the contract',
          'An entry was promptly made to the Migration Department to submit documents',
          'Opening an account with the Bank of Cyprus',
          'Upon arrival in Cyprus, the children immediately started school',
          'Moving to a rented apartment within 2 weeks',
          'Submission of documents to the Migration Department for obtaining a residence permit - Visitor',
        ]
      },
      {
        title: 'Creating a more efficient business/corporate environment for CyCo',
        descr: `The client owns a Cyprus company ("CyCo") under the management of a service provider ("SP"). The Beneficiary
                was dissatisfied with the quality of service and the established relationship between the Beneficiary and SP. 
                CyCo was registered at the address of SP and with nominee directors and secretary. Accounting and auditing 
                services were also provided by SP.`,
        task: `Create a more efficient business/corporate environment for CyCo due to the Cyprus tax legislation, optimize processes 
               for the Beneficiary and establish effective communication between the Beneficiary and the SP.`,
        done: [
          {
            title: 'The "One Window Principle" was introduced',
            descr: `By changing the corporate structure, CyCo: SmarDynamics represents the interests of the Beneficiary 
                    in Cyprus and interacts with the SP in Cyprus, implementing the tasks set by the Beneficiary`,
          },
          {
            title: 'Appointment to the position of Director',
            descr: `The services of a nominal registration address, a nominal secretary have been transferred to a reliable 
                    and verified SP with a change in the policy of interaction with the Beneficiary. A new accountant and an auditor
                    under supervision were appointed. The archive and registers and the entire document flow have been transferred to
                    SmarDynamics.`
          },
        ],
        result: [
          'Effective administration and management that solved the issue of physical presence and bank checks',
          'Effective communication: convenient ways for the Beneficiary to control and monitor CyCo in Cyprus',
          'Less time is spent on completing tasks and strict compliance with deadlines',
          'Budget optimization: cost reduction by at least 15%',
          'Advantages of the tax regime in Cyprus',
        ]
      },
      {
        title: 'Organization of physical presence in Cyprus',
        descr: `The client owns a holding company in Cyprus under the management of SP. The beneficiary intends to send 
                some employees to Cyprus to manage a holding company on the island.`,
        task: `Organize a physical presence in Cyprus, optimize business processes and create an effective business environment for CyCo.`,
        done: [
          {
            title: 'Registration with the Migration Department',
            descr: `Now it has the right to issue work permits to employees from third countries. Active assistance with 
                    the preparation of documents and visits to the Migration Department`,
          },
          {
            title: 'A step-by-step action plan has been developed',
            descr: `And the project budget controlled by SmarDynamics with a timely report on budget development and 
                    project implementation status`
          },
          {
            title: 'The "One Window Principle" was introduced',
            descr: `SmarDynamics is appointed as the authorized representative of the Beneficiary in Cyprus – the process 
                    of interaction between the SP and the Beneficiary goes through SmarDynamics directly in Cyprus`
          },
          {
            title: 'Organization of business infrastructure on time Rented office with subsequent acquisition',
            descr: `Organization of business infrastructure on time An office was rented with the subsequent purchase 
                    of furniture, equipment and computer equipment`
          },
        ],
        result: [
          'Effective communication: convenient ways for the Beneficiary to control and monitor CyCo',
          'Less time is spent on completing tasks',
          'Development of transparent and efficient business processes',
          'Creating a reliable team in the office',
          'Ensuring a physical presence in Cyprus greatly simplifies the management of the holding company and optimizes interaction within the group\'s business structure',
        ]
      },
      {
        title: 'Sale of a car registered in Cyprus',
        descr: `A company registered in Cyprus has purchased a used car from an individual. The vehicle was reissued to the company, 
                the payment was made to a third person`,
        task: `To carry out compliance with the purchase and sale of a vehicle, as well as the risks arising when making 
               payments to third persons`,
        done: [
          {
            title: 'Collection and analysis of documents',
          },
          {
            title: 'Risk management',
          },
          {
            title: 'Preparation of a vehicle purchase and sale agreement',
          },
          {
            title: 'Preparation of the act of acceptance and transfer of the vehicle',
          },
        ],
        result: [
          'A complete package of documents has been prepared for the purchase of a vehicle and its registration as a legal entity',
          'The parties have fulfilled their obligations, the payment issue has been resolved - the company has successfully passed the audit for the reporting period',
        ]
      },
    ],
  },

  // Tariffs section
  tariffs: {
    title: 'Our prices',
    items: [
      {
        title: 'Hourly',
        price: '100€',
      },
      {
        title: 'Monthly',
        price: '1500€',
      },
      {
        title: 'Project',
        price: '250€',
      },
    ],
    comments: `* the average values are given in the prices<br />
               ** the price does not include VAT, fees, duties and other expenses`,
  },

  // Contacts section
  contacts: {
    title: `You can contact us in any way convenient for you:`,
    items: [
      {
        icon: 'sms',
        title: 'info@smardynamics.com.cy',
        href: 'mailto:info@smardynamics.com.cy',
      },
      {
        icon: 'call',
        title: '+357 99 143 884',
        href: 'tel:+35799143884',
      },
      {
        icon: 'instagram',
        title: 'smardynamicscy',
        href: 'https://instagram.com/smardynamicscy?igshid=MzRlODBiNWFlZA==',
      },
    ],
  },

  // Footer
  footer: {
    items: [
      {
        title: 'info@smardynamics.com.cy',
        href: 'mailto:info@smardynamics.com.cy',
      },
      {
        title: '+357 99 143 884',
        href: 'tel:+35799143884',
      },
      {
        title: 'smardynamics',
        href: 'https://instagram.com/smardynamics?igshid=MzRlODBiNWFlZA==',
      },
      {
        title: 'smardynamicscy',
        href: 'https://instagram.com/smardynamicscy?igshid=MzRlODBiNWFlZA==',
      },
    ],
  },

  // Popup
  callme: {
    title: 'Write to us, we will be happy to answer your questions',
    name: 'Your namr',
    phone: 'Telephone',
    email: 'E-mail',
    subject: 'Theme',
    message: 'Message',
    agreement: 'Consent to personal data processing',
    sendButton: 'Send',
    successText: `<p>We thank you for contacting our company.</p>
                  <p>Our team will contact with you soon</p>`,
  },
};
