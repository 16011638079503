import styled, { css } from 'styled-components';
import { Icon, Slide, Text } from '@components';
import { laptop } from '@styles';

export const StyledServices = styled.section`
  padding-top: 60px;

  ${laptop(css`
    padding-top: 100px;
  `)}
`;

export const StyledSlide = styled(Slide)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 10px;

  ${laptop(css`
    flex-direction: row;
    align-items: stretch;
  `)}
`;

export const Service = styled.div`
  flex: 1 1 100%;
  background: ${({ theme }) => theme.colors.bg};
  box-shadow: 0 4px 4px rgba(160, 160, 160, 0.25);
  border-radius: 29px;
  padding: 50px 20px 80px;
`;

export const Head = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 70px;
  margin-bottom: 35px;
  padding-left: 35px;
`;

export const Number = styled(Text).attrs(() => ({
  textColor: 'first',
}))`
  position: absolute;
  top: 50%;
  left: -15px;
  width: 40px;
  transform: translateY(-50%);
  font-size: 93px;
  text-align: right;
`;

export const Title = styled(Text).attrs(() => ({
  weight: 700,
}))`
  font-size: 25px;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: 20px;
  line-height: 1.2;
  margin-bottom: 20px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  flex: 0 0 16px;
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;

  ${laptop(css`
    flex-direction: row;
    gap: 50px;
    margin: 60px 0 10px;
  `)}
`;

export const Tab = styled.button<{ tabActive: boolean; }>`
  font-family: ${({ theme }) => theme.family};
  font-size: ${({ theme }) => theme.fontSize.xl}px;
  font-weight: 700;
  color: ${({ theme, tabActive }) => !tabActive ? '#A6A6A6' : theme.colors.text};
  border: 0;
  outline: none;
  background: none;
  padding: 0;
  cursor: pointer;

  ${laptop(css`
    font-size: 32px;
  `)}
`;