import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, IconType, Text } from '@components';
import * as C from '@styles';
import { laptop } from '@styles';
import { useMatchMedia } from '@utils';
import { breakpoints } from '@config';
import { Data } from '../../interfaces/data';

export interface DirectionProps {
  data: Data;
}

const StyledDirection = styled.section`
  padding-top: 60px;

  ${laptop(css`
    padding-top: 95px;
  `)}
`;

const Cards = styled.div`
  padding-top: 30px;

  ${laptop(css`
    display: grid;
    grid-template: repeat(5, 1fr) / repeat(2, 1fr);
    gap: 80px;
    padding-top: 60px;
  `)}
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 30px;
  
  &:last-child {
    margin-bottom: 0;
  }

  ${laptop(css`
    column-gap: 45px;
    margin-bottom: 0;
  `)}
`;

const Number = styled(Icon)`
  flex: 0 0 50px;
  background-position: top right;

  ${laptop(css`
    flex: 0 0 150px;
  `)}
`;

export const Direction: React.FC<DirectionProps> = ({ data: { direction } }) => {
  const laptopMatch = useMatchMedia(breakpoints.laptop);

  return (
    <StyledDirection>
      <C.Container>
        <C.SectionTitle as={'h2'} dangerouslySetInnerHTML={{ __html: direction.title } } />
        <Cards>
          {
            direction.items.map(({ descr }, index) => (
              <Card key={index}>
                <Number glyph={`d${index + 1}` as IconType} iconSize={laptopMatch ? 150 : 50} />
                <Text textSize={laptopMatch ? 'xl' : 'md'} lineHeight={1.6}>{descr}</Text>
              </Card>
            ))
          }
        </Cards>
      </C.Container>
    </StyledDirection>
  );
};
