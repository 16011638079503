import { useEffect, useState } from 'react';

/**
 * Определяем тип устройства
 * @param {string} breakpoint Точка перелома
 * @returns {boolean}
 */
export const useMatchMedia: (breakpoint: string) => boolean = (breakpoint) => {
  const [ matches, setMatches ] = useState(false);

  const handleChange: (e: MediaQueryListEvent) => void = (e) => {
    setMatches(e.matches);
  };
  
  useEffect(() => {
    const matchMedia = window.matchMedia(`(min-width: ${breakpoint})`);
    matchMedia?.addEventListener('change', handleChange);
    setMatches(matchMedia.matches);
  }, [ breakpoint ]);
  
  return matches;
};
