import React from 'react';
import styled from 'styled-components';
import { Text } from '@components';
import * as C from '@styles';
import { Data } from '../../interfaces/data';
import { assetsMapping } from './assets';

export interface FooterProps {
  data: Data;
}

const StyledFooter = styled.footer`
  background: ${({ theme }) => theme.colors.second};
  padding: 20px 0;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
`;

const Link = styled(Text).attrs(() => ({
  textColor: 'textLight',
  textSize: 'xs',
}))`
  text-decoration: none;
`;

const Logo = styled.img`
  max-width: 138px;
  height: auto;
  margin-left: auto;
`;

export const Footer: React.FC<FooterProps> = ({ data: { footer } }) => (
  <StyledFooter>
    <C.Container>
      <Wrapper>
        <Links>
          {
            footer.items.map(({ title, href }, index) => (
              <Link key={index} as={'a'} href={href}>{title}</Link>
            ))
          }
        </Links>
        <Logo src={assetsMapping.logo} />
      </Wrapper>
    </C.Container>
  </StyledFooter>
);
