import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Text } from '@components';
import { laptop } from '@styles';

export const Button = styled.button`
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 0;
  outline: none;
  background: ${({ theme }) => theme.colors.first};
  box-shadow: 0 2.92414px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  z-index: 999;

  ${laptop(css`
    width: 106px;
    height: 106px;
    bottom: 40px;
    right: 40px;
  `)}
`;

export const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba('#000000', 0.2)};
  z-index: 10;
`;

export const Popup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 270px;
  width: 100%;
  background: ${({ theme }) => theme.colors.bg};
  box-sizing: border-box;
  padding: 30px 22px;
  z-index: 20;
`;

export const Input = styled.input`
  font-family:  ${({ theme }) => theme.family};
  font-size:  ${({ theme }) => theme.fontSize.sm}px;
  width: 100%;
  height: 33px;
  border: 1px solid #D9D9D9;
  outline: none;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 10px;
  margin-top: 15px;
  
  &::placeholder {
    color: #D9D9D9;
    opacity: 1;
    font-family:  ${({ theme }) => theme.family};
    font-size:  ${({ theme }) => theme.fontSize.sm}px;
  }
`;

export const Textarea = styled(Input)`
  height: 132px;
  padding-top: 10px;
`;

export const Agreement = styled.div<{ active: boolean; }>`
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.first};
  line-height: 1;
  cursor: pointer;
  margin-top: 10px;
  
  &::before {
    display: block;
    content: '';
    width: 13px;
    height: 13px;
    border: 1px solid ${({ theme }) => theme.colors.first};
    background: ${({ theme, active }) => active && theme.colors.first};
  }
`;

export const SendButton = styled.button`
  font-family:  ${({ theme }) => theme.family};
  height: 33px;
  background: ${({ theme }) => theme.colors.first};
  color: ${({ theme }) => theme.colors.textLight};
  border-radius: 39px;
  border: 0;
  outline: none;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0 30px;
  margin-top: 10px;
  cursor: pointer;
`;

export const Success = styled(Text).attrs(() => ({
  lineHeight: 1.3,
}))`
  text-align: center;
`;

export const Logo = styled.img`
  max-width: 172px;
  height: auto;
  margin-bottom: 30px;
`;

export const Error = styled.div`
  font-size: 14px;
  color: red;
  margin-top: 20px;
`;