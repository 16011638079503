import React, { useEffect, useState } from 'react';
import { LocaleSelector, Icon } from '@components';
import * as C from '@styles';
import { useMatchMedia } from '@utils';
import { breakpoints } from '@config';
import { Data, Locale } from '../../interfaces/data';
import * as S from './styles';

export interface HeaderProps {
  data: Data;
  sectionActive: (to: string, options?: any) => void;
  onSelectLocale: (currentLocale: Locale) => void;
}

export const Header: React.FC<HeaderProps> = ({ data: { header }, sectionActive, onSelectLocale }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [currentLocale, setCurrentLocale] = useState<Locale>('ru');
  const laptopMatch = useMatchMedia(breakpoints.laptop);

  useEffect(() => {
    onSelectLocale(currentLocale);
  }, [currentLocale, onSelectLocale]);

  return (
    <S.Header>
      <C.Container>
        <S.Wrapper show={showMenu}>
          {
            header.items.map(({ title, to, offset, options }, index) => (
              <S.Link
                key={index}
                to={to}
                offset={offset}
                onClick={() => sectionActive(to, options)}
              >
                {title}
              </S.Link>
            ))
          }
          {
            laptopMatch
            && <LocaleSelector onSelectLocale={(currentLocale) => setCurrentLocale(currentLocale)} />
          }
        </S.Wrapper>
        <S.MenuButton onClick={() => setShowMenu(!showMenu)}>
          <Icon glyph={'hamburger'} iconSize={40} />
        </S.MenuButton>
        {
          !laptopMatch
          && <S.MobileLocaleSelector onSelectLocale={(currentLocale) => setCurrentLocale(currentLocale)} />
        }
      </C.Container>
    </S.Header>
  );
};
