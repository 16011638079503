import { createGlobalStyle } from 'styled-components';
import { Theme } from '../interfaces/styled';

export interface GlobalStylesProps {
  theme: Theme;
}

const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  html {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: ${({ theme }) => theme.family};
    font-size: ${({ theme }) => theme.fontSize.md}px;
    font-weight: 400;
    line-height: 1.1;
    background: ${({ theme }) => theme.colors.bg};
    margin: 0;
  }
`;

export { GlobalStyles };
