import { Data } from '../interfaces/data';

export const ru: Data = {

  // Header section
  header: {
    items: [
      {
        title: 'О нас',
        to: 'about',
      },
      {
        title: 'Корпоративным клиентам',
        to: 'services',
        options: {
          tab: 'corporate',
        },
      },
      {
        title: 'Частным клиентам',
        to: 'services',
        options: {
          tab: 'individual',
        },
      },
      {
        title: 'Наши преимущества',
        to: 'pluses',
      },
      {
        title: 'Стоимость услуг',
        to: 'tariffs',
        offset: -70,
      },
      {
        title: 'Наши кейсы',
        to: 'cases',
      },
      {
        title: 'Контакты',
        to: 'contacts',
      },
    ],
  },

  // Promo section
  promo: {
    title: 'Кипр',
    subtitle: `Консалтинговые услуги<br />
              для корпоративных и частных клиентов`,
  },

  // About section
  about: {
    title: 'О нас',
    items: [
      {
        icon: 'job',
        value: '2018',
        title: 'Дата создания компании',
      },
      {
        icon: 'rating',
        value: '15',
        title: 'Экспертов',
      },
      {
        icon: 'handshake',
        value: '80+',
        title: 'Лояльных клиентов',
      },
      {
        icon: 'community',
        value: '5+',
        title: 'Партнеров',
      },
      {
        icon: 'project',
        value: '100+',
        title: 'Успешно реализованных проектов',
      },
    ],
  },

  // Direction section
  direction: {
    title: 'Мы работаем с 10 профессиональными направлениями',
    items: [
      {
        descr: 'Регистрация и администрирование, а также управление юридическими лицами на Кипре и не только',
      },
      {
        descr: 'Создание физического присутствия / открытие офисов «под ключ» на территории Кипра',
      },
      {
        descr: 'Миграционные услуги',
      },
      {
        descr: 'Операции с недвижимостью',
      },
      {
        descr: 'Риск-менеджмент и управление проектами',
      },
      {
        descr: 'Бухгалтерское сопровождение и аудит',
      },
      {
        descr: 'Управление недвижимостью',
      },
      {
        descr: 'HR-менеджмент',
      },
      {
        descr: 'Банковское администрирование',
      },
      {
        descr: 'Служба заботы о клиентах',
      },
    ],
  },

  // Services
  services: {
    title: 'Наши услуги',
    locale: {
      corporateTab: 'Корпоративным клиентам',
      individualTab: 'Частным клиентам',
    },
    corporate: [
      [
        {
          title: 'Регистрация/ Администрирование/ Управление юридических лиц',
          items: [
            {
              icon: 'search-normal',
              descr: 'KYC и комплексная проверка',
            },
            {
              icon: 'path',
              descr: 'Регистрация компаний',
            },
            {
              icon: 'layer',
              descr: 'Получение документов из реестра',
            },
            {
              icon: 'copy',
              descr: 'Ведение корпоративных реестров и документооборота',
            },
            {
              icon: 'cloud',
              descr: 'Хранение файла компании',
            },
            {
              icon: 'search-normal',
              descr: 'Составление и проверка корпоративных документов',
            },
            {
              icon: 'category',
              descr: 'Re-domiciliation юридических лиц на Кипр',
            },
            {
              icon: 'shield-tick',
              descr: 'Легализация и заверение документов (нотариусом, апостиль)',
            },
          ],
        },
        {
          title: 'Создание физического присутствия/ открытие офисов «под ключ» на территории Кипра',
          items: [
            {
              icon: 'setting',
              descr: 'Аренда офиса',
            },
            {
              icon: 'profile-2-user',
              descr: 'Подбор сотрудников ',
            },
            {
              icon: 'user-octagon',
              descr: 'Услуги по управлению юридическим лицом',
            },
            {
              icon: 'tag-user',
              descr: 'Консультирование по вопросам физического присутствия',
            },
            {
              icon: 'copy',
              descr: 'Организация инфраструктуры офиса (в том числе IT инфраструктуру) ',
            },
          ],
        },
      ],
      [
        {
          title: 'Бюджетирование / Планирование / Банковское ведение',
          items: [
            {
              icon: 'path',
              descr: 'Открытие и ведение банковских счетов',
            },
            {
              icon: 'copy',
              descr: 'Оформление отчёта по исполнению бюджета',
            },
            {
              icon: 'box-search',
              descr: 'Подготовка документов для оформления займа',
            },
            {
              icon: 'receipt-discount',
              descr: 'Прохождение процедуры соответствия KYC and банковским и брокерским запросам',
            },
            {
              icon: 'dollar-circle',
              descr: 'Планирование бюджета проекта или компании, а также его исполнение и контроль',
            },
            {
              icon: 'message-text',
              descr: 'Оформление и исполнение банковских платежных поручений (процедуры AML проверки транзакций)',
            },
          ],
        },
        {
          title: 'Бухгалтерский учет и аудит',
          items: [
            {
              icon: 'tag-user',
              descr: 'Подготовка финансовой отчетности',
            },
            {
              icon: 'layer',
              descr: 'Подготовка расчетов налогов за отчетный период',
            },
            {
              icon: 'copy',
              descr: 'Подача отчетности в Реестр',
            },
            {
              icon: 'setting',
              descr: 'Ведение бухгалтерских реестров и бухгалтерских книг ',
            },
            {
              icon: 'services-sms',
              descr: 'Подготовка и подача налоговых деклараций, а также оплата налогов',
            },
            {
              icon: 'user-octagon',
              descr: 'Осуществление ежемесячных заработных выплат сотрудникам с подготовкой соответствующих документов и оплатой взносов в ДСС',
            },
          ],
        },
      ],
      [
        {
          title: 'Миграционные услуги',
          items: [
            {
              icon: 'directbox-notif',
              descr: 'Оформление ВНЖ членам семьи сотрудников компаний',
            },
            {
              icon: 'data',
              descr: 'Служба заботы о клиентах – услуги по релокации сотрудников',
            },
            {
              icon: 'path',
              descr: 'Регистрация компаний в Миграционном департаменте',
            },
            {
              icon: 'message-text',
              descr: 'Оформление разрешений на работу для сотрудников ',
            },
            {
              icon: 'copy',
              descr: 'Помощь в подготовке документов и сопровождение в миграционную службу ',
            },
          ],
        },
        {
          title: 'Налоговое консультирование',
          items: [
            {
              icon: 'moneys',
              descr: 'Расчет корпоративного налога',
            },
            {
              icon: 'tag-user',
              descr: 'Подача налоговых деклараций в налоговые органы',
            },
            {
              icon: 'layer',
              descr: 'Консультирование в отношении налогового режима на территории Кипра',
            },
            {
              icon: 'discount-shape',
              descr: 'Регистрация компаний в Налоговых органах и в Департаменте соцстрахования',
            },
            {
              icon: 'services-sms',
              descr: 'Подача отчетности в органы социального страхования, если требуется',
            },
          ],
        },
      ],
      [
        {
          title: 'Процесс подбора персонала',
          items: [
            {
              icon: 'services-people',
              descr: 'Поиск кандидатов',
            },
            {
              icon: 'copy',
              descr: 'Подготовка трудовых договоров ',
            },
            {
              icon: 'receipt-discount',
              descr: 'Расчет налогов и сборов',
            },
            {
              icon: 'profile-tick',
              descr: 'Проведение интервью с потенциальными кандидатами ',
            },
            {
              icon: 'dollar-circle',
              descr: 'Начисление заработной платы и оформление расчетных квитанций',
            },
            {
              icon: 'message-text',
              descr: 'Подача соответствующих отчетов в государственные органы',
            },
            {
              icon: 'discount-shape',
              descr: 'Проведение взаиморасчетов с налоговыми органами и органами социального страхования',
            },
          ],
        },
        {
          title: 'Дополнительные услуги',
          items: [
            {
              icon: 'brush',
              descr: 'Перевод документов',
            },
            {
              icon: 'key-square',
              descr: 'Апостиль/ легализация документов',
            },
            {
              icon: 'copy',
              descr: 'Копии документов',
            },
            {
              icon: 'services-sms',
              descr: 'Подготовка электронного архива Клиента',
            },
            {
              icon: 'layer',
              descr: 'Заверение документов и перевода документов на иностранный язык',
            },
          ],
        }
      ],
    ],
    individual: [
      [
        {
          title: 'Миграционные услуги',
          items: [
            {
              icon: 'message-text',
              descr: 'Консультирование по вопросам существующих вариантов разрешений (виз)',
            },
            {
              icon: 'house',
              descr: 'Служба заботы о клиентах – услуги по релокации сотрудников',
            },
            {
              icon: 'path',
              descr: 'Регистрация компаний в Миграционном департаменте',
            },
            {
              icon: 'copy',
              descr: 'Оформление ВНЖ членам семьи третьих стран/граждан Кипра',
            },
            {
              icon: 'directbox-notif',
              descr: 'Служба заботы о клиентах – услуги по релокации семей',
            },
          ],
        },
        {
          title: 'Налоговое консультирование',
          items: [
            {
              icon: 'layer',
              descr: 'Подготовка финансовой отчетности',
            },
            {
              icon: 'receipt-discount',
              descr: 'Подготовка расчетов налогов за отчетный период',
            },
            {
              icon: 'setting',
              descr: 'Подача отчетности в Реестр',
            },
            {
              icon: 'edit',
              descr: 'Ведение бухгалтерских реестров и бухгалтерских книг ',
            },
            {
              icon: 'services-sms',
              descr: 'Подготовка и подача налоговых деклараций, а также оплата налогов',
            },
            {
              icon: 'user-octagon',
              descr: 'Осуществление ежемесячных заработных выплат сотрудникам с подготовкой соответствующих документов и оплатой взносов в ДСС',
            },
          ],
        },
      ],
      [
        {
          title: 'Служба заботы о клиентах',
          items: [
            {
              icon: 'user',
              descr: 'Услуги консьержа',
            },
            {
              icon: 'user',
              descr: 'Услуги переводчика',
            },
            {
              icon: 'bank',
              descr: 'Сопровождение в государственные органы',
            },
            {
              icon: 'clock',
              descr: 'Адаптация после релокации',
            },
            {
              icon: 'building-3',
              descr: 'Подбор детсадов/школ/ВУЗов',
            },
            {
              icon: 'truck',
              descr: 'Транспортные услуги',
            },
          ],
        },
        {
          title: 'Управление проектами',
          items: [
            {
              icon: 'edit',
              descr: 'Планирование',
            },
            {
              icon: 'layer',
              descr: 'Структурирование проектов',
            },
            {
              icon: 'frame',
              descr: 'Пошаговый план реализации проекта',
            },
            {
              icon: 'setting',
              descr: 'Наблюдение и контроль за реализацией проекта',
            },
            {
              icon: 'services-flash',
              descr: 'Управление рисками',
            },
            {
              icon: 'services-sms',
              descr: 'Подготовка требующихся документов',
            },
            {
              icon: 'shield-tick',
              descr: 'Легализация документов',
            },
            {
              icon: 'keyboard',
              descr: 'При необходимости, получение лицензий и соответствующих разрешений',
            },
          ],
        },
      ],
      [
        {
          title: 'Операции с недвижимостью',
          items: [
            {
              icon: 'repeate-music',
              descr: 'Покупка/Продажа/сдача в аренду',
            },
            {
              icon: 'document',
              descr: 'Подготовка договоров аренды',
            },
            {
              icon: 'house',
              descr: 'Обслуживание недвижимости',
            },
            {
              icon: 'house',
              descr: 'Подбор недвижимости/земельного участка по запросу',
            },
            {
              icon: 'receipt-2',
              descr: 'Юридическая проверка недвижимости на первичном рынке',
            },
            {
              icon: 'receipt-2',
              descr: 'Юридическая проверка застройщика недвижимости',
            },
          ],
        },
        {
          title: 'Дополнительные услуги',
          items: [
            {
              icon: 'brush',
              descr: 'Перевод документов',
            },
            {
              icon: 'key-square',
              descr: 'Апостиль/ легализация документов',
            },
            {
              icon: 'copy',
              descr: 'Копии документов',
            },
            {
              icon: 'services-sms',
              descr: 'Подготовка электронного архива Клиента',
            },
            {
              icon: 'layer',
              descr: 'Заверение документов и перевода документов на иностранный язык',
            },
          ],
        },
      ],
    ]
  },

  // Pluses section
  pluses: {
    title: 'Наши преимущества',
    items: [
      {
        icon: 'people',
        title: 'Команда квалифицированных профессионалов',
      },
      {
        icon: 'user',
        title: 'Клиентоориентированность',
      },
      {
        icon: 'programming-arrow',
        title: 'Оперативная обратная связь',
      },
      {
        icon: 'flash',
        title: 'Эффективный клиентский сервис',
      },
      {
        icon: 'profile',
        title: 'Индивидуальный подход',
      },
      {
        icon: 'programming-arrows',
        title: 'Комплексный подход в решении задач',
      },
      {
        icon: 'like',
        title: 'Разумная и прозрачная стоимость услуг',
      },
      {
        icon: 'lamp',
        title: 'Креативность в решениях',
      },
      {
        icon: 'receipt',
        title: 'Гибкие условия сотрудничества и оплаты',
      },
    ],
  },

  // Cases
  cases: {
    title: 'Наши кейсы',
    locale: {
      descrTitle: 'Краткая информация',
      taskTitle: 'Задача',
      resultTitle: 'Результат',
    },
    items: [
      {
        title: 'Переезд семьи из четырех человек на Кипр',
        descr: `Семья из четырёх человек ( 2 взрослых и 2 детей) планировала переезд на Кипр. Клиент рассматривает остров в долгосрочной перспективе. `,
        task: `Определиться с миграционным статусом для семьи с последующей подачей документов на получение ВНЖ. Переезд семьи в кратчайшие сроки на Кипр. `,
        done: [
          {
            title: 'Оперативно подключена Служба заботы о клиентах',
          },
          {
            title: 'Подобран оптимальный вариант ВНЖ и оптимальный миграционный статус.',
          },
          {
            title: 'Проведена консультация по налоговому резидентству и налогообложению на Кипре.',
          },
          {
            title: 'Подготовка документов для открытия счета в банке на Кипре',
          },
          {
            title: 'Подготовка соответствующего пакета документов, в том числе помощь с переводом и легализацией документов',
          },
          {
            title: 'Подбор школы для детей и начало занятий до получения ВНЖ',
          },
          {
            title: 'Подбор квартиры в соответствии с запросом Клиента, оформление договора аренды и сопутствующих документов',
          },
          {
            title: 'Сопровождение при прохождении медкомиссии на Кипре',
          },
          {
            title: 'Сопровождение Клиента в Миграционный департамент',
          },
        ],
        result: [
          'Переезд на Кипр состоялся через месяц после заключения договора',
          'Оперативно оформлена запись в Миграционный департамент на подачу документов',
          'Открытие счета в Банке Кипра',
          'По прибытию на Кипр дети сразу начали обучение в школе',
          'Переезд в съёмную квартиру в течение 2-х недель',
          'Подача документов в Миграционный департамент на получение ВНЖ - Visitor',
        ]
      },
      {
        title: 'Создание более эффективной бизнес/ корпоративной среды для CyCo',
        descr: `Клиент владеет кипрской компанией («CyCo») под управлением сервис-провайдера («SP»). Бенефициар был 
                недоволен качеством обслуживания и установившейся связи между Бенефициаром и SP. CyCo была зарегистрирована 
                по адресу SP и с номинальными директорами и секретарем. Также бухгалтерские и аудиторские услуги предоставлялись SP.`,
        task: `Создать более эффективную бизнес/ корпоративную среду для CyCo благодаря налоговому законодательству Кипра, 
               оптимизировать процессы для Бенефициара и установить эффективную коммуникацию между Бенефициаром и SP.`,
        done: [
          {
            title: 'Был внедрен «Принцип одного окна»',
            descr: `Путем изменения корпоративной структуры CyCo: SmarDynamics представляет интересы Бенефициара на 
                    Кипре и взаимодействует с SP на территории Кипра, реализуя поставленные Бенефициаром задачи`,
          },
          {
            title: 'Назначение на должность директора',
            descr: `Услуги номинального регистрационного адреса, номинального секретаря переданы надежному и 
                    проверенному SP с изменением политики взаимодействия с Бенефициаром. Были назначены новые бухгалтер 
                    и аудитор под наблюдением. Архив и реестры и весь документооборот передан SmarDynamics.`
          },
        ],
        result: [
          'Эффективное администрирование и управление, решившее вопрос с физическим присутствием и банковскими проверками',
          'Эффективная коммуникация: удобные для Бенефициара пути контроля и наблюдения за CyCoна территории Кипра',
          'Меньше времени затрачивается на выполнение задач и четкое соблюдение сроков',
          'Оптимизация бюджета: сокращение расходов минимум на 15%',
          'Преимущества налогового режима на территории Кипра',
        ]
      },
      {
        title: 'Организация физического присутствия на Кипре',
        descr: `Клиент владеет холдинговой компанией на Кипре под управлением SP. Бенефициар намерен направить 
                на Кипр некоторых сотрудников для управления холдинговой компанией на острове.`,
        task: `Организовать физическое присутствие на Кипре, оптимизировать бизнес-процессы и создать эффективную бизнес среду для CyCo.`,
        done: [
          {
            title: 'Регистрация в Миграционном департаменте',
            descr: `Теперь она обладает правом выдачи разрешений на работу сотрудникам из третьих стран. Активная 
                    помощь с подготовкой документов и посещениями Миграционного департамента`,
          },
          {
            title: 'Разработан пошаговый план действия',
            descr: `И бюджет проекта, контролируемый SmarDynamics с своевременным отчетом по освоению бюджета и статусу реализации проекта`
          },
          {
            title: 'Был внедрен «Принцип одного окна»',
            descr: `SmarDynamics назначен авторизованным представителем Бенефициара на Кипре –процесс взаимодействия между 
                    SP и Бенефициаром идет через SmarDynamics непосредственно на Кипре`
          },
          {
            title: 'Организация бизнес инфраструктуры в срок Арендован офис с последующим приобретением',
            descr: `Организация бизнес инфраструктуры в срок Арендован офис с последующим приобретением мебели, 
                    оборудования и компьютерной техники`
          },
        ],
        result: [
          'Эффективная коммуникация: удобные для Бенефициара пути контроля и наблюдения за CyCo',
          'Меньше времени затрачивается на выполнение задач',
          'Разработка прозрачных и эффективных бизнес процессов',
          'Создание надежной команды в офисе',
          'Обеспечение физического присутствия на Кипре значительно упрощает управление холдинговой компанией и оптимизирует взаимодействие в рамках бизнес-структуры группы',
        ]
      },
      {
        title: 'Продажа автомобиля, зарегистрированного на Кипре',
        descr: `Компания, зарегистрированная на Кипре, приобрела автомобиль с пробегом у физического лица. Транспортное 
                средство было переоформлено на компанию, оплата прошла третьему лицу`,
        task: `Провести комплаенс сделки приобретения купли-продажи транспортного средства, а также возникающие риски 
               при проведении оплаты третьим лицам`,
        done: [
          {
            title: 'Сбор и анализ документов',
          },
          {
            title: 'Риск-менеджмент',
          },
          {
            title: 'Подготовка договора купли-продажи транспортного средства',
          },
          {
            title: 'Подготовка акта приема передачи транспортного средства',
          },
        ],
        result: [
          'Подготовлен полный пакет документов по купле транспортного средства и его регистрации на юридическое лицо',
          'Стороны выполнили свои обязательства, вопрос оплаты разрешён - компания успешно прошла аудит за отчетный период',
        ]
      },
    ],
  },

  // Tariffs section
  tariffs: {
    title: 'Стоимость услуг',
    items: [
      {
        title: 'Час',
        price: '100€',
      },
      {
        title: 'Месяц',
        price: '1500€',
      },
      {
        title: 'Проект',
        price: '250€',
      },
    ],
    comments: `* в стоимости приведены средние значения<br />
              ** стоимость не включает НДС, сборы, пошлины и прочие расходы`,
  },

  // Contacts section
  contacts: {
    title: `Вы можете связаться с&nbsp;нами любым<br />
            удобным для Вас способом:`,
    items: [
      {
        icon: 'sms',
        title: 'info@smardynamics.com.cy',
        href: 'mailto:info@smardynamics.com.cy',
      },
      {
        icon: 'call',
        title: '+357 99 143 884',
        href: 'tel:+35799143884',
      },
      {
        icon: 'instagram',
        title: 'smardynamics',
        href: 'https://instagram.com/smardynamics?igshid=MzRlODBiNWFlZA==',
      },
    ],
  },

  // Footer
  footer: {
    items: [
      {
        title: 'info@smardynamics.com.cy',
        href: 'mailto:info@smardynamics.com.cy',
      },
      {
        title: '+357 99 143 884',
        href: 'tel:+35799143884',
      },
      {
        title: 'smardynamics',
        href: 'https://instagram.com/smardynamics?igshid=MzRlODBiNWFlZA==',
      },
      {
        title: 'smardynamicscy',
        href: 'https://instagram.com/smardynamicscy?igshid=MzRlODBiNWFlZA==',
      },
    ],
  },

  // Popup
  callme: {
    title: 'Напишите нам, мы с удовольствием ответим на Ваши вопросы',
    name: 'Ваше имя',
    phone: 'Телефон',
    email: 'E-mail',
    subject: 'Тема',
    message: 'Сообщение',
    agreement: 'Согласие на обработку данных',
    sendButton: 'Отправить',
    successText: `<p>Мы благодарим Вас за обращение в нашу компанию.</p>
                  <p>Наша команда свяжется с Вами в ближайшее время.</p>`,
  },
}