import styled, { css } from 'styled-components';
import { laptop } from '@styles';
import { Link as ScrollLink } from 'react-scroll/modules';
import { LocaleSelector, MoreLocale } from '@components';

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;
  background: ${({ theme }) => theme.colors.bg};
  z-index: 999;
  
  ${laptop(css`
    height: auto;
  `)}
`;

export const Wrapper = styled.div<{ show: boolean; }>`
  position: absolute;
  top: 40px;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.bg};
  transform: ${({ show }) => !show ? 'translateX(-100%)' : 'none'};
  transition: all 0.5s ease;
  padding: 20px 0;

  ${laptop(css`
    position: static;
    width: auto;
    flex-direction: row;
    align-items: stretch;
    height: 65px;
    transform: none;
    padding: 0;
  `)}
`;

export const Link = styled(ScrollLink)`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.sm}px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 24px;

  ${laptop(css`
    flex: auto;
    justify-content: center;
    text-align: center;
    padding: 0 10px;
  `)}
  
  &:hover {
    color: ${({ theme }) => theme.colors.textLight};
    background: ${({ theme }) => theme.colors.second};
  }
`;

export const MenuButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5px;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 10px;
  margin: auto 0;
  z-index: 10;

  ${laptop(css`
    display: none;
  `)}
`;

export const MobileLocaleSelector = styled(LocaleSelector)`
  position: absolute;
  top: 20px;
  right: 5px;
  z-index: 10;
  
  ${MoreLocale} {
    top: 40px;
    right: 10px;
  }
`;