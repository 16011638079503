import { useState } from 'react';
import {
  About,
  Direction,
  Header,
  Pluses,
  Promo,
  Tariffs,
  Contacts,
  Footer,
  Cases,
  Services,
  ServicesTab, CallMe
} from '@components';
import * as localData from '../../data';
import { Locale } from '../../interfaces/data';

export const Page = () => {
  const [locale, setLocale] = useState<Locale>('ru');
  const [serviceTabActive, setServiceTabActive] = useState<ServicesTab>('corporate');

  const handlerSectionActive = (to: string, options?: any) => {
    if (to === 'services') {
      setServiceTabActive(options?.tab);
    }
  };

  return (
    <>
      <Header
        data={localData[locale]}
        sectionActive={handlerSectionActive}
        onSelectLocale={(currentLocale) => setLocale(currentLocale)}
      />
      <Promo data={localData[locale]} />
      <About data={localData[locale]} />
      <Direction data={localData[locale]} />
      <Services data={localData[locale]} currentTab={serviceTabActive} />
      <Pluses data={localData[locale]} />
      <Cases data={localData[locale]} />
      <Tariffs data={localData[locale]} />
      <Contacts data={localData[locale]} />
      <Footer data={localData[locale]} />
      <CallMe data={localData[locale]} />
    </>
  );
};
