import community from '@images/icons/community.svg';
import handshake from '@images/icons/handshake.svg';
import job from '@images/icons/job.svg';
import project from '@images/icons/project.svg';
import rating from '@images/icons/rating.svg';
import d1 from '@images/icons/d-1.svg';
import d2 from '@images/icons/d-2.svg';
import d3 from '@images/icons/d-3.svg';
import d4 from '@images/icons/d-4.svg';
import d5 from '@images/icons/d-5.svg';
import d6 from '@images/icons/d-6.svg';
import d7 from '@images/icons/d-7.svg';
import d8 from '@images/icons/d-8.svg';
import d9 from '@images/icons/d-9.svg';
import d10 from '@images/icons/d-10.svg';
import user from '@images/icons/user.svg';
import flash from '@images/icons/flash.svg';
import lamp from '@images/icons/lamp.svg';
import like from '@images/icons/like.svg';
import people from '@images/icons/people.svg';
import profile from '@images/icons/profile.svg';
import hamburger from '@images/icons/hamburger.svg';
import programmingArrow from '@images/icons/programming-arrow.svg';
import programmingArrows from '@images/icons/programming-arrows.svg';
import receipt from '@images/icons/receipt.svg';
import sms from '@images/icons/sms.svg';
import call from '@images/icons/call.svg';
import instagram from '@images/icons/instagram.svg';
import arrowLeft from '@images/icons/arrow-left.svg';
import arrowRight from '@images/icons/arrow-right.svg';
import directInbox from '@images/icons/direct-inbox.svg';
import boxSearch from '@images/icons/services/box-search.svg';
import copy from '@images/icons/services/copy.svg';
import dollarCircle from '@images/icons/services/dollar-circle.svg';
import layer from '@images/icons/services/layer.svg';
import messageText from '@images/icons/services/message-text.svg';
import path from '@images/icons/services/path.svg';
import receiptDiscount from '@images/icons/services/receipt-discount.svg';
import setting from '@images/icons/services/setting.svg';
import servicesSms from '@images/icons/services/sms.svg';
import tagUser from '@images/icons/services/tag-user.svg';
import userOctagon from '@images/icons/services/user-octagon.svg';
import data from '@images/icons/services/data.svg';
import directboxNotif from '@images/icons/services/directbox-notif.svg';
import discountShape from '@images/icons/services/discount-shape.svg';
import moneys from '@images/icons/services/moneys.svg';
import brush from '@images/icons/services/brush.svg';
import keySquare from '@images/icons/services/key-square.svg';
import servicesPeople from '@images/icons/services/people.svg';
import profileTick from '@images/icons/services/profile-tick.svg';
import category from '@images/icons/services/category.svg';
import cloud from '@images/icons/services/cloud.svg';
import profile2User from '@images/icons/services/profile-2user.svg';
import searchNormal from '@images/icons/services/search-normal.svg';
import shieldTick from '@images/icons/services/shield-tick.svg';
import bank from '@images/icons/services/bank.svg';
import building3 from '@images/icons/services/building-3.svg';
import clock from '@images/icons/services/clock.svg';
import edit from '@images/icons/services/edit.svg';
import servicesFlash from '@images/icons/services/flash.svg';
import frame from '@images/icons/services/frame.svg';
import house from '@images/icons/services/house.svg';
import keyboard from '@images/icons/services/keyboard.svg';
import receipt2 from '@images/icons/services/receipt-2.svg';
import repeateMusic from '@images/icons/services/repeate-music.svg';
import truck from '@images/icons/services/truck.svg';
import document from '@images/icons/services/document.svg';
import arrowDown from '@images/icons/arrow-down.svg';
import ruFlag from '@images/icons/ru-flag.svg';
import enFlag from '@images/icons/en-flag.svg';

export const assetsMapping = {
  community,
  handshake,
  job,
  project,
  rating,
  d1,
  d2,
  d3,
  d4,
  d5,
  d6,
  d7,
  d8,
  d9,
  d10,
  user,
  flash,
  lamp,
  like,
  people,
  profile,
  hamburger,
  'programming-arrow': programmingArrow,
  'programming-arrows': programmingArrows,
  receipt,
  sms,
  call,
  instagram,
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
  'direct-inbox': directInbox,
  'box-search': boxSearch,
  copy,
  'dollar-circle': dollarCircle,
  layer,
  'message-text': messageText,
  path,
  'receipt-discount': receiptDiscount,
  setting,
  'services-sms': servicesSms,
  'tag-user': tagUser,
  'user-octagon': userOctagon,
  data,
  'directbox-notif': directboxNotif,
  'discount-shape': discountShape,
  moneys,
  brush,
  'key-square': keySquare,
  'services-people': servicesPeople,
  'profile-tick': profileTick,
  category,
  cloud,
  'profile-2-user': profile2User,
  'search-normal': searchNormal,
  'shield-tick': shieldTick,
  bank,
  'building-3': building3,
  clock,
  edit,
  'services-flash': servicesFlash,
  frame,
  house,
  keyboard,
  'receipt-2': receipt2,
  'repeate-music': repeateMusic,
  truck,
  document,
  'arrow-down': arrowDown,
  'ru-flag': ruFlag,
  'en-flag': enFlag,
};
