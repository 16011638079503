import React from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Text } from '@components';
import * as C from '@styles';
import { laptop } from '@styles';
import { Data } from '../../interfaces/data';
import { assetsMapping } from './assets';

export interface PromoProps {
  data: Data;
}

const StyledPromo = styled.section`
  background: ${({ theme }) => theme.colors.second};

  ${laptop(css`
    background: url('${assetsMapping['promo-bg']}') no-repeat center;
    background-size: cover;
    background-position: center 65px;
  `)}
`;

const Logo = styled.img`
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  max-width: 120px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  
  @media (min-width: 1400px) {
    top: 128px;
    max-width: 172px;
  }
`;

const Wrapper = styled.div`
  text-align: center;

  ${laptop(css`
    max-width: 800px;
    width: 100%;
    background: ${rgba('#D9D9D9', 0.3)};
    border-radius: 35px;
    backdrop-filter: blur(2px);
    box-sizing: border-box;
    padding: 30px 15px;
  `)}
`;

const Title = styled(Text).attrs(() => ({
  weight: 700,
  textColor: 'textLight',
}))`
  font-size: 84px;
  margin: 0 0 20px;

  ${laptop(css`
    margin-bottom: 40px;
  `)}
`;

const Subtitle = styled(Text).attrs(({ theme }) => ({
  textColor: 'textLight',
  lineHeight: 1.2,
}))`
  font-size: ${({ theme }) => theme.fontSize.xl}px;

  ${laptop(css`
    font-size: 34px;
  `)}
`;

export const Promo: React.FC<PromoProps> = ({ data: { promo } }) => (
  <StyledPromo>
    <C.Container fullHeight center>
      <Logo src={assetsMapping.logo} alt={promo.title} />
      <Wrapper>
        <Title as={'h1'}>{promo.title}</Title>
        <Subtitle dangerouslySetInnerHTML={{ __html: promo.subtitle }} />
      </Wrapper>
    </C.Container>
  </StyledPromo>
);
