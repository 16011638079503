import styled, { css } from 'styled-components';
import { Icon, Slider, Text } from '@components';
import { laptop } from '@styles';

export const StyledCases = styled.div`
  padding-top: 60px;

  ${laptop(css`
    padding-top: 90px;
  `)}
`;

export const StyledSlider = styled(Slider)`
  ${laptop(css`
    padding-top: 40px;
  `)}
`;

export const Case = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${laptop(css`
    display: grid;
    grid-template: repeat(3, auto) / repeat(2, 1fr);
    grid-template-areas: 'head head' 'descr task' 'done result';
    gap: 65px 25px;
  `)}
`;

export const Head = styled.div`
  grid-area: head;
  display: flex;
  align-items: center;
  column-gap: 30px;
`;

export const Number = styled(Icon)`
  flex: 0 0 50px;
  background-position: top right;
  mask-position: top right;

  ${laptop(css`
    flex: 0 0 150px;
  `)}
`;

export const Title = styled(Text).attrs(() => ({
  lineHeight: 1.4,
}))`
  font-size: ${({ theme }) => theme.fontSize.xl}px;

  ${laptop(css`
    font-size: 44px;
  `)}
`;

export const SubTitle = styled(Text).attrs(() => ({
  textSize: 'lg',
  weight: 700,
  lineHeight: 1.1,
}))`
  margin-bottom: 20px;
`;

export const Bordered = styled(Text).attrs(() => ({
  textSize: 'xs',
  lineHeight: 1.5,
}))`
  border: 3px solid ${({ theme }) => theme.colors.first};
  border-radius: 29px;
  padding: 20px 30px;
`;

export const Descr = styled(Bordered)`
  grid-area: descr;
`;

export const Task = styled(Bordered)`
  grid-area: task;
  border: 3px solid ${({ theme }) => theme.colors.first};
  border-radius: 29px;
  padding: 20px 30px;
`;

export const Done = styled(Text).attrs(() => ({
  textSize: 'xs',
  lineHeight: 1.5,
}))`
  grid-area: done;
  list-style: none;
  padding: 24px 0 0;
  margin: 0;
`;

export const DoneItem = styled.li`
  position: relative;
  padding-left: 50px;
  margin-bottom: 40px;
  
  &:last-child {
    margin-bottom: 40px;
  }
`;

export const DoneNumber = styled(Text).attrs(() => ({
  lineHeight: 1,
}))`
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 40px;
`;

export const Result = styled.div`
  grid-area: result;
`;

export const ResultInner = styled(Text).attrs(() => ({
  textSize: 'xs',
  lineHeight: 1.5,
  textColor: 'textLight',
}))`
  background: ${({ theme }) => theme.colors.second};
  border-radius: 29px;
  padding: 24px;
`;

export const ResultList = styled.ul`
  padding-left: 15px;
`;

export const ResultItem = styled.li`
  padding-left: 10px;
  margin-bottom: 20px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;