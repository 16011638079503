import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as C from '@styles';
import { Slider } from '@components';
import { Data } from '../../interfaces/data';
import * as S from './styles';
import { useMatchMedia } from '@utils';
import { breakpoints } from '@config';

export interface ServicesProps {
  data: Data;
  currentTab: ServicesTab;
}

export type ServicesTab = 'corporate' | 'individual';

export const Services: React.FC<ServicesProps> = ({ data: { services }, currentTab }) => {
  const [data, setData] = useState<Data['services'][ServicesTab]>(services.corporate);
  const [sliderData, setSliderData] = useState<Data['services'][ServicesTab]>(data);
  const [tabActive, setTabActive] = useState<ServicesTab>('corporate');
  const [restart, setRestart] = useState(false);
  const laptopMatch = useMatchMedia(breakpoints.laptop);
  let number = 0;

  const toggleTab = useCallback((tabName: ServicesTab) => {
    setData(services[tabName]);
    setTabActive(tabName);
    setRestart(true);
  }, [services]);

  const handlerToggleSlide = () => {
    if (restart) {
      setRestart(false);
    }
  };

  useEffect(() => {
    toggleTab(currentTab);
  }, [currentTab, toggleTab]);

  useEffect(() => {
    if (laptopMatch) {
      setSliderData(data);
    } else {
      const mergedData = data.flat(1).map((item) => [item]);
      setSliderData(mergedData)
    }
  }, [laptopMatch, data]);

  return (
    <S.StyledServices id={'services'}>
      <C.Container>
        <C.SectionTitle as={'h2'}>{services.title}</C.SectionTitle>
        <S.Tabs>
          <S.Tab onClick={() => toggleTab('corporate')} tabActive={tabActive === 'corporate'}>{services.locale.corporateTab}</S.Tab>
          <S.Tab onClick={() => toggleTab('individual')} tabActive={tabActive === 'individual'}>{services.locale.individualTab}</S.Tab>
        </S.Tabs>
        <Slider restart={restart} toggleSlide={handlerToggleSlide}>
          {
            sliderData.map((items, index) => (
              <S.StyledSlide key={index}>
                {
                  items.map(({ title, items }, index) => {
                    number += 1;

                    return (
                      (
                        <S.Service key={index}>
                          <S.Head>
                            <S.Number>{number}</S.Number>
                            <S.Title>{title}</S.Title>
                          </S.Head>
                          <S.List>
                            {
                              items.map(({ icon, descr }, index) => (
                                <S.ListItem key={index}>
                                  <S.StyledIcon glyph={icon} iconSize={16} />
                                  {descr}
                                </S.ListItem>
                              ))
                            }
                          </S.List>
                        </S.Service>
                      )
                    )
                  })
                }
              </S.StyledSlide>
            ))
          }
        </Slider>
      </C.Container>
    </S.StyledServices>
  );
};
