import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, Text } from '@components';
import * as C from '@styles';
import { laptop } from '@styles';
import { Data } from '../../interfaces/data';

export interface PlusesProps {
  data: Data;
}

const StyledPluses = styled.section`
  padding-top: 60px;

  ${laptop(css`
    padding-top: 95px;
  `)}
`;

const Cards = styled.div`
  padding-top: 20px;

  ${laptop(css`
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
    gap: 20px;
    padding-top: 50px;
  `)}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  text-align: center;
  border: 3px solid ${({ theme }) => theme.colors.first};
  border-radius: 9px;
  padding: 30px 20px;
  margin-bottom: 20px;
  
  &:last-child {
    margin-bottom: 0;
  }

  ${laptop(css`
    margin-bottom: 0;
  `)}
`;

export const Pluses: React.FC<PlusesProps> = ({ data: { pluses } }) => (
  <StyledPluses id={'pluses'}>
    <C.Container>
      <C.SectionTitle as={'h2'} dangerouslySetInnerHTML={{ __html: pluses.title } } />
      <Cards>
        {
          pluses.items.map(({ icon, title }, index) => (
            <Card key={index}>
              <Icon glyph={icon} iconSize={33} />
              <Text textSize={'lg'} lineHeight={1.2}>{title}</Text>
            </Card>
          ))
        }
      </Cards>
    </C.Container>
  </StyledPluses>
);
