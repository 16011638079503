import { createGlobalStyle } from 'styled-components';

const FontsStyles = createGlobalStyle`
  @font-face {
    font-family: Candara;
    src: url('/fonts/candara-400.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: Candara;
    src: url('/fonts/candara-italic-400.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
  }
  
  @font-face {
    font-family: Candara;
    src: url('/fonts/candara-700.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: Candara;
    src: url('/fonts/candara-italic-700.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
  }
`;

export { FontsStyles };
