import React, { useEffect, useState } from 'react';
import { Locale } from '../../interfaces/data';
import { Icon, IconType } from '@components';
import styled from 'styled-components';

export interface LocalSelectorProps {
  className?: string;
  onSelectLocale: (currentLocale: Locale) => void;
}

const locales = {
  ru: {
    icon: 'ru-flag',
    name: 'Рус',
  },
  en: {
    icon: 'en-flag',
    name: 'Eng',
  },
};

const SelectLocale = styled.div`
  position: relative;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 10px;
`;

const CurrentLocale = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 100%;
  cursor: pointer;
`;

export const MoreLocale = styled.div<{ show: boolean; }>`
  position: absolute;
  top: 50px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  background: ${({ theme }) => theme.colors.bg};
  border: 1px solid ${({ theme }) => theme.colors.second};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  visibility: ${({ show }) => show ? 'visibility' : 'hidden'};
  opacity: ${({ show }) => show ? 1 : 0};
  cursor: pointer;
  padding: 10px 20px;
  z-index: 10;
`;

export const LocaleSelector: React.FC<LocalSelectorProps> = ({ className, onSelectLocale }) => {
  const [currentLocale, setCurrentLocale] = useState<Locale>('ru');
  const [moreLocale, setMoreLocale] = useState<Locale>('en');
  const [showSelectLocale, setShowSelectLocale] = useState(false);

  const selectLocale = () => {
    setCurrentLocale(moreLocale);
    setShowSelectLocale(false);
  };

  useEffect(() => {
    const more = Object.keys(locales).filter((locale) => locale !== currentLocale)[0] as Locale;
    setMoreLocale(more);
  }, [currentLocale]);

  useEffect(() => {
    onSelectLocale(currentLocale);
  }, [currentLocale, onSelectLocale]);

  return (
    <SelectLocale className={className}>
      <CurrentLocale onClick={() => setShowSelectLocale(!showSelectLocale)}>
        <Icon glyph={locales[currentLocale].icon as IconType} iconSize={16} />
        {locales[currentLocale].name}
        <Icon glyph={'arrow-down'} iconSize={10} />
      </CurrentLocale>
      <MoreLocale show={showSelectLocale} onClick={selectLocale}>
        <Icon glyph={locales[moreLocale].icon as IconType} iconSize={16} />
        {locales[moreLocale].name}
      </MoreLocale>
    </SelectLocale>
  );
};
